@value viewports: "../../viewports.css";
@value desktop from viewports;

.app-layout--grid {
    display: grid;
    grid-template-columns: 1fr 0;
    grid-template-rows: 1fr;
    transition: 0.4s ease;
    overflow: hidden;
    height: 100%;
}

.app-layout--slider-open {
    grid-template-columns: 1fr 30%;
}

.slider {
    width: 30vw;
    height: 100%;
    z-index: var(--z2000);
}

.main {
    overflow: auto;
    height: 100dvh;
}
