@value viewports: "../../../viewports.css";
@value desktop from viewports;

.story-editor-card {
  --story-card-width: 102.4rem;
  background-color: var(--white);
  position: relative;
  padding: 8px;
  margin: 0.8rem auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.4s ease-in-out;
}

@media (min-width: desktop) {
  .story-editor-card {
    padding: 2.4rem 4.8rem 0;
  }
  .story-editor-card.is-open {
    padding: 0;
  }
}

.story-editor-card-key-event {
  color: var(--mono-4);
  cursor: pointer;
  margin-inline: 0.25rem;
  display: flex;
  justify-content: flex-end;
}

.story-editor-card-actions {
  display: flex;
  justify-content: flex-start;
  transition: opacity, visibility, transform 0.2s;
}

.story-editor-listicle-header {
  flex-grow: 1;
}

.story-editor-card-actions .story-editor-card-action.pin {
  opacity: 1;
  visibility: visible;
}

.story-editor-card-action {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mono-4);
  transition: opacity, visibility, transform 0.2s;
}

.story-editor-card-action-mobile {
  display: flex;
  justify-content: flex-end;
  margin-inline: 0.25rem;
}

.story-editor-card-action.card-has-error svg {
  color: var(--danger);
}

.story-editor-card-content {
  max-width: var(--story-editor-width);
  margin: 0 auto;
}

.story-editor-card-error-message {
  display: inline-flex;
  align-items: center;
  color: var(--danger);
  font-size: var(--fs-xs);
  font-weight: var(--fw-normal);
}

.story-editor-card-header {
  max-width: var(--story-card-width);
  position: relative;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-inline: auto;
  margin-bottom: 1rem;
}

.story-editor-card-add-element {
  position: absolute;
  bottom: 0.8rem;
}

@media (min-width: desktop) {
  .story-editor-card-action {
    opacity: 0;
    visibility: hidden;
  }

  [dir="ltr"] .story-editor-card-action {
    transform: translateX(3rem);
  }

  [dir="rtl"] .story-editor-card-action {
    transform: translateX(-3rem);
  }

  .story-editor-card:hover:not(.block-card) .story-editor-card-action,
  .story-editor-card:hover .story-editor-card-settings {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .story-editor-card-actions .story-editor-card-action.pin {
    transform: translateY(0);
  }

  .story-editor-card-action-desktop {
    display: flex;
  }

  .story-editor-card-key-event {
    width: auto;
  }

  .story-editor-card-actions {
    min-width: 16rem;
  }

  .story-editor-card-error-message {
    margin: 0 0 1.6rem 10rem;
  }

  .story-editor-card-add-element {
    bottom: 4rem;
  }

  [dir="ltr"] .story-editor-card-add-element {
    left: 26rem;
  }

  [dir="rtl"] .story-editor-card-add-element {
    right: 26rem;
  }
}

.story-editor-card-wordcount {
  text-align: right;
  height: 1.6rem;
  padding: 0 2.4rem;
  color: var(--mono-4);
  font-size: var(--fs-xs);
  font-weight: var(--fw-semi-bold);
}

@media (min-width: desktop) {
  .story-editor-card-wordcount {
    height: 3.6rem;
    max-width: var(--story-card-width);
    margin: 0 auto;
  }
}
