/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.prosemirror-text-area-editor {
  border-radius: 3px;
  background-color: var(--white);
  border: 1px solid var(--mono-3);
  min-height: 6.4rem;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
  line-height: var(--lh-3);
  word-break: break-word;
}

.prosemirror-text-area-editor-wrapper {
  margin-bottom: 0.8rem;
}

.has-error .prosemirror-text-area-editor {
  border-color: var(--danger);
}
