/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

:root {
  /* ------------------Color Pallet------------------- */
  /* Brand Colors */
  --brand-1: #e5e7ed;
  --brand-2: #e1eaf7;
  --brand-3: #4860bc;
  --brand-4: #324383;
  /* Monochromatic colors */
  --mono-1: #f9f9f9;
  --mono-2: #f0f1f5;
  --mono-3: #c6ccd5;
  --mono-4: #5f6978;
  --mono-5: #132437;
  /* Action Colors */
  --danger: #ff214b;
  --success: #2fd072;
  --warning: #f5a623;
  --white: #fff;
  --black: #000;
  /* Accent colors */
  --accent-1: #f8e71c;
  --accent-2: #7842b9;
  --accent-3: #50e3c2;
  --accent-4: #f5a623;
  --ascent-5: #fdf3dc;

  /* Ad hoc colors */
  --vivid-purple:#7657FF;
  --teal-green: #409F8E;

  /* Typography */
  --systemType: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --brandType: "Open Sans", sans-serif;

  /* Font Scale */
  --fs-xxs: 1rem;
  --fs-xs: 1.2rem;
  --fs-s: 1.4rem;
  --fs-m: 1.6rem;
  --fs-l: 1.8rem;
  --fs-xl: 2rem;
  --fs-xxl: 2.4rem;
  --fs-jumbo: 4rem;

  /* Line height */
  --lh-1: 1;
  --lh-2: 1.2;
  --lh-3: 1.3;
  --lh-4: 1.4;
  --lh-5: 1.5;
  --lh-6: 1.6;
  --lh-7: 1.7;

  /* Font Weight */
  --fw-light: 300;
  --fw-normal: 400;
  --fw-semi-bold: 600;
  --fw-bold: 700;

  /* Widths */
  --navbar-width: 7.2rem;
  --sidebar-width: 24rem;
  --action-bar-width: 24rem;
  --navbar-sidebar-width: calc(var(--navbar-width) + var(--sidebar-width));
  --header-height: 6.4rem;
  --banner-height: 3.6rem;
  --banner-height-mobile: 7.2rem;
  --story-editor-width: 91.2rem;
  --story-element-width: 72.4rem;
  --timeline-width: 35rem;
  --story-inspector-width: 48rem;
  --preview-width: 48rem;
  --format-toolbar-width: 41rem;

  /* Z index scale */
  --z-1: -1;
  --z1: 1;
  --z100: 100;
  --z200: 200;
  --z300: 300;
  --z600: 600;
  --z700: 700;
  --z800: 800;
  --z900: 900;
  --z1000: 1000;
  --inspectorLevel0: 1000;
  --inspectorLevel1: 1100;
  --inspectorLevel2: 1200;
  --confirmationModal: 1300;
  --googleSearchDropdown: 1400;
  /*Following is slider z index value. Dont declare any z index value
  more than below value unless it needs to shown on top of slider*/
  --z2000: 2000;
  --snackbarNotification: 2100;
  --photoEditor: 2100;

  /* Box shadow scale */
  --box-shadow-s: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-m: 0 3px 6px 0 rgba(19, 36, 55, 0.1);
  --box-shadow-ml: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
  --box-shadow-l: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-2: 0 0 25px 0 rgba(0, 0, 0, 0.2);
}

/* Resets */
html {
  box-sizing: border-box;

  /* Scaffolding html */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  font: normal normal normal 1em / 1 var(--brandType);
  font-size: 62.5%;

  /* Spacing */
  --space-xxxs: 2px;
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
  --space-huge: 48px;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: var(--mono-5);
  background: var(--mono-1);
  font-size: var(--fs-m);
}

img {
  max-width: 100%;
}

p {
  margin: 0 0 1.6rem 0;
}

input,
textarea {
  line-height: var(--lh-2);
}

.page-container {
  transition: transform 0.5s;
}

:focus {
  outline: 1px dashed var(--mono-4);
}
