/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

.story-page-container {
  background: var(--white);
}

.sidebar.is-active + .story-page-container {
  margin-left: initial;
}

.story-page-container .story-card {
  background-color: var(--white);
  padding: 3.2rem 6.4rem;
}

.editor-container {
  position: relative;
}

.editor-container label textarea {
  width: 100%;
}

/* Temp */
.story-element-question-container p,
.story-element-answer-container p {
  margin: 1rem 0;
}

.story-element-quote,
.story-element-blockquote,
.story-element-bigfact {
  color: var(--mono-4);
}

.editor-container .card .story-element {
  margin-bottom: 1.6rem;
}

.editor {
  position: relative;
}

.editor-container .file-upload {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: var(--brandType);
  font-size: var(--fs-l);
  line-height: var(--lh-5);
  color: var(--mono-4);
}

.editor-container .file-upload .info {
  font-size: var(--fs-xs);
  grid-column: 1/2;
}

.editor-container .file-upload .file-field {
  font-size: var(--fs-m);
  margin-bottom: 0;
}

:global .ProseMirror-selectednode,
:global .ProseMirror-focused {
  outline: none !important;
}

:global .ProseMirror .placeholder::before {
  color: var(--mono-3);
  pointer-events: none;
  content: attr(data-placeholder);
  position: absolute;
}

.settings-container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  top: 6.4rem;
  padding: 2.4rem 0 2.4rem 0;
}

.story-page-preview--open .settings-container {
  padding-right: 0.8rem;
}

:global .story-editor-chip {
  font-size: var(--fs-xl) !important;
}

.settings-container .story-template-settings {
  cursor: pointer;
  display: flex;
  align-items: center;
}

[dir="ltr"] .settings-container .story-template-settings {
  margin-left: 1.2rem;
}

[dir="rtl"] .settings-container .story-template-settings {
  margin-right: 1.2rem;
}

.story-template-story-size {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.story-size {
  font-size: var(--fs-xs);
  padding: 0.8rem;
}

.settings-container .story-template-settings.disabled {
  pointer-events: none;
}

.settings-container .manage-settings {
  display: grid;
  grid-template-columns: 1fr 5.6rem;
}

.manage-inspector-container {
  padding: 0 1.6rem;
}

.manage-inspector-container button:last-child {
  margin-top: 0.8rem;
}

.manage-inspector-container .fixed-tab-list {
  margin: 0 -3.2rem;
}

.story-page-grid-container {
  display: grid;
  grid-template-columns: auto;
}
.story-page-preview--open {
  grid-template-columns: auto var(--preview-width);
}

.story-page-timeline--open {
  grid-template-columns: auto var(--timeline-width);
}

.story-editor-inspector--open {
  grid-template-columns: auto 48rem;
}

.story-page-validator--open {
  grid-template-columns: auto var(--timeline-width);
}

.block-container {
  position: absolute;
  top: 10rem;
  right: 0;
  bottom: 0;
  left: 0;
}

.story-editor-last-updated {
  margin: 1.6rem 0 1.6rem 6rem;
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

.story-editor-version-bar {
  position: absolute;
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(19, 36, 55, 0.1);
  border-radius: 0.2rem;
  min-width: 75rem;
  height: 4.8rem;
  background-color: var(--mono-4);
  color: var(--white);
  opacity: 0.9;
  margin: 0 0 0.6rem 6rem;
  padding: 1.5rem;
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  z-index: var(--z200);
}

.story-editor-version-bar.timeline--open,
.story-editor-version-bar.story-editor-inspector--open,
.story-editor-version-bar.preview--open,
.story-editor-version-bar.seoScore--open {
  position: unset;
}

.story-editor-version-go-back {
  cursor: pointer;
}

.story-editor-version-text {
  padding-left: 5rem;
}

.story-page.loader {
  margin: 30%;
}

:global .story-title-text-area {
  font-weight: var(--fw-bold);
}

.story-page-header-wrapper {
  background-color: inherit;
  box-shadow: var(--box-shadow-m);
}

.story-page-header-container {
  position: relative;
  margin: 0 auto;
}

:global .story-editor-manage-btn-mobile {
  z-index: var(--z700);
  min-width: 5.8rem;
}

.story-page-grid-story-contents {
  overflow-x: hidden;
}

[dir="ltr"] .settings-versions-container {
  padding: 5.4rem 0.8rem 0 0;
}

[dir="rtl"] .settings-versions-container {
  padding: 5.4rem 0 2.4rem 0.8rem;
}

.embargo-banner-container {
  position: relative;
  display: flex;
  top: 10rem;
  height: 11rem;
}

.embargo-banner-not-present {
  position: relative;
  display: flex;
  z-index: 999;
  height: 11rem;
}

.manage-container {
  top: 6.4rem;
  background-color: var(--white);
  position: fixed;
  z-index: var(--z800);
  width: 100%;
  will-change: transform;
  transition: transform 0.4s ease;
  transform: translateY(0)
}

[dir="ltr"] .manage-container {
  padding-right: 0.8rem;
}

[dir="rtl"] .manage-container {
  padding-left: 0.8rem;
}

.manage-container.banner-present {
  top: 13.6rem;
}

.manage-container--hide {
  transform: translateY(-12rem);
}

@media (min-width: desktop) {
  .story-page-header-container {
    max-width: var(--story-editor-width);
  }

  .story-page-header-container .settings-versions-container {
    padding: 0;
  }

  .settings-versions-container.timeline--open {
    position: relative;
  }

  .settings-versions-container.validator--open {
    position: relative;
  }

  .settings-version-container.story-editor-inspector--open {
    position: relative;
  }

  [dir="ltr"] .story-page-header-container {
    padding: 0 2.4rem 2.4rem 0;
  }

  [dir="rtl"] .story-page-header-container {
    padding: 0 0 2.4rem 2.4rem;
  }

  .settings-container {
    width: var(--story-editor-width);
    display: grid;
    grid-template-columns: 9.6rem 1fr 1fr;
    padding: 2.4rem 0;
  }

  .settings-container.timeline--open,
  .settings-container.preview--open,
  .settings-container.seoScore--open {
    grid-template-columns: 6rem 0.75fr 1fr;
    padding: 2.4rem 0 0 0;
  }

  .settings-container.story-editor-inspector--open {
    grid-template-columns: 6rem 0.75fr 1fr;
    padding: 2.4rem 0 0 0;
  }

  .settings-container .manage-settings {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    grid-template-columns: 1fr 8.8rem;
  }

  .manage-container {
    display: flex;
    justify-content: space-evenly;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 100vw;
    transition: .4s ease;
  }

  .manage-container.slider-window--open {
    width: 67vw;
    right: 30%;
  }

  [dir="rtl"] .manage-container.slider-window--open {
      left: 30%;
      right: unset;
  }

  [dir="ltr"] .manage-container {
    padding-right: unset;
  }

  [dir="rtl"] .manage-container {
    padding-left: unset;
  }

  .manage-container.timeline--open,
  .manage-container.preview--open,
  .manage-container.story-editor-inspector--open,
  .manage-container.seoScore--open {
    position: unset;
    transform: unset;
    width: unset;
  }

  .manage-container.banner-present {
    top: 10rem;
  }

  [dir="ltr"] .settings-container .story-template-settings {
    margin-left: unset;
  }

  [dir="rtl"] .settings-container .story-template-settings {
    margin-right: unset;
  }

  .story-size {
    padding: unset;
  }

  [dir="ltr"] .story-size {
    margin-left: 0.8rem;
  }

  [dir="rtl"] .story-size {
    margin-right: 0.8rem;
  }

  .story-template-story-size {
    align-items: center;
    flex-direction: unset;
  }

  .embargo-banner-container {
    position: relative;
    justify-content: space-evenly;
    display: flex;
    left: 0;
    top: 4rem;
    height: 11rem;
  }

  [dir="ltr"] .embargo-banner-container {
    margin-right: 4rem;
  }

  [dir="rtl"] .embargo-banner-container {
    margin-left: 4rem;
  }

  .embargo-inspector-open {
    padding-right: unset;
    margin-left: unset;
    padding-left: unset;
    margin-right: 1rem;
  }

  .embargo-seo-pane-open {
    margin-right: 4rem;
  }

  .manage-container--hide {
    transform: translateY(-6rem);
  }
}
